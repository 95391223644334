<template>
  <div class="container-fluid mt-5" v-if="salesOrder">
    <div>
      <div class="soumission">
        <div class="soumission-left">
          <div class="logo">
            <img src="../../../../public/laniel-logo.svg" alt="logo" />
          </div>
          <div class="soumission-left-header">
            <h3>{{ $t("COMMON.SALES_ORDER") }} #{{ salesOrder.seq_number }}</h3>
            <div class="address">
              <div class="address-left">
                <ul>
                  <li class="address-title">{{ $t("COMMON.ADRESSED_TO") }}:</li>
                  <li class="address-location">
                    {{ salesOrder.recipient.company_name }}
                  </li>
                  <li>
                    <phone-number
                      :phoneNumber="salesOrder.recipient.phone"
                      :extension="salesOrder.recipient.phone_extension"
                      :type="salesOrder.recipient.phone_type"
                    />
                  </li>
                  <li>{{ salesOrder.recipient.address }}</li>
                  <li>{{ salesOrder.recipient.email }}</li>
                </ul>
              </div>
              <div class="address-right">
                <ul>
                  <li>{{ $t("COMMON.DATE") }}:</li>
                  <li>
                    {{
                      $timeZoneDateFormat(salesOrder.created_at, "YYYY-MM-DD")
                    }}
                  </li>
                </ul>
                <ul>
                  <li>{{ $t("COMMON.EXPIRY_DATE") }}:</li>
                  <li>
                    {{
                      $timeZoneDateFormat(
                        salesOrder.expiration_time,
                        "YYYY-MM-DD"
                      )
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="description">
              <div class="description-header">
                <p>{{ $t("SALES_ORDERS.SALES_ORDER_DESCRIPTION") }}</p>
                <button @click="downloadPdf">
                  <img
                    src="../../../../public/file-pdf-outline.svg"
                    alt="icon"
                  />
                  <span>{{ $t("SALES_ORDERS.DOWNLOAD_PDF") }}</span>
                </button>
              </div>
              <div class="description-table">
                <el-table :data="salesOrder.items" style="width: 100%">
                  <el-table-column
                    :label="$t('SALES_ORDERS.PRODUCT_SERVCIE')"
                    min-width="180"
                  >
                    <template v-slot="{ row }">
                      {{
                        row.salesOrderable
                          ? row.salesOrderable.name
                          : row.salesOrderable.code
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('COMMON.EXCERPT')"
                    min-width="304"
                  >
                    <template v-slot="{ row }">
                      <div v-html="row.excerpt"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="quantity"
                    :label="$t('SALES_ORDERS.QTY')"
                    min-width="80"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="amount"
                    label="Montant"
                    min-width="100"
                  >
                    <template v-slot="{ row }">
                      {{ $formatCurrency(row.unit_price) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('COMMON.DISCOUNT')"
                    min-width="80"
                  >
                    <template v-slot="{ row }">
                      {{ $formatCurrency(row.pricing.discount_amount) }}
                      <span class="text-muted">({{ row.discount }}%)</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('COMMON.TAXES')" min-width="80">
                    <template v-slot="{ row }">
                      {{ $formatCurrency(row.pricing.taxes.total) }}
                    </template>
                  </el-table-column>
                </el-table>
                <div class="sub-table">
                  <ul>
                    <li>{{ $t("COMMON.SUBTOTAL") }}</li>
                    <li>{{ $formatCurrency(salesOrder.pricing.subtotal) }}</li>
                  </ul>
                  <ul>
                    <li>{{ $t("COMMON.DISCOUNTS") }}</li>
                    <li>
                      {{
                        $formatCurrency(
                          salesOrder.pricing.discounts.amount * -1
                        )
                      }}
                    </li>
                  </ul>
                  <ul>
                    <li>{{ $t("COMMON.TAXES") }}</li>
                    <li>
                      {{ $formatCurrency(salesOrder.pricing.taxes.total) }}
                    </li>
                  </ul>
                  <ul>
                    <li>{{ $t("COMMON.TOTAL") }}</li>
                    <li>{{ $formatCurrency(salesOrder.pricing.total) }}</li>
                  </ul>
                  <ul class="sold">
                    <li>{{ $t("SALES_ORDERS.OWED_AMOUNT") }}</li>
                    <li>{{ $formatCurrency(salesOrder.pricing.total) }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="terms">
              <p v-if="salesOrder.paymentTerm">
                <strong>
                  {{ $t("COMMON.PAYMENT_TERMS") }} :
                  {{ salesOrder.paymentTerm.name }}
                </strong>
              </p>
              <div v-html="salesOrder.terms_conditions"></div>
            </div>
          </div>
        </div>
        <div class="soumission-right">
          <div class="validation">
            <img src="../../../../public/check-outline.svg" alt="icon" />
            <span>{{ $t("SALES_ORDERS.VALIDATION") }}</span>
          </div>
          <p>
            {{ $t("SALES_ORDERS.VALIDATION_MESSAGE") }}
          </p>
          <form>
            <div class="form-group-wrapper half">
              <base-input
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                :label="`${$t('COMMON.LASTNAME')} *`"
                v-model="validatorInfos.lastName"
              />
            </div>
            <div class="form-group-wrapper half">
              <base-input
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                :label="`${$t('COMMON.FIRSTNAME')} *`"
                v-model="validatorInfos.firstName"
              />
            </div>
            <div class="form-group-wrapper full">
              <base-input
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                :label="`${$t('COMMON.EMAIL')} *`"
                v-model="validatorInfos.email"
              />
            </div>
            <div class="form-group-wrapper full">
              <base-input
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                :label="`${$t('COMMON.POSITION')} *`"
                v-model="validatorInfos.position"
              />
            </div>
            <div class="form-group-wrapper textarea full">
              <label>{{ $t("SALES_ORDERS.COMMENT") }}</label>
              <el-input
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                type="textarea"
                v-model="validatorInfos.comment"
              >
              </el-input>
            </div>
            <div class="events-info">
              <ul class="date">
                <li>{{ $t("SALES_ORDERS.EVENT_DATE") }} :</li>
                <li>{{ $timeZoneDateFormat(salesOrder.event_date, "LL") }}</li>
              </ul>
              <ul class="time">
                <li>{{ $t("SALES_ORDERS.EVENT_TIME") }} :</li>
                <li>
                  {{ $timeZoneDateFormat(salesOrder.event_date, "HH:mm") }}
                </li>
              </ul>
              <ul class="location">
                <li>{{ $t("SALES_ORDERS.DELIVERY_LOCATION") }} :</li>
                <li v-html="salesOrder.delivery_location"></li>
              </ul>
            </div>
            <div class="form-group-wrapper checkbox full">
              <base-checkbox
                :disabled="
                  salesOrder.client_validated_status !=
                  CLIENT_VALIDATED_STATUS_DRAFT
                "
                v-model="checked"
              >
                <span>
                  <i class="far fa-check"></i>
                </span>
                {{ $t("SALES_ORDERS.ACCEPT_TERMS_AND_CONDITIONS") }}
              </base-checkbox>
            </div>
            <div
              class="buttons"
              v-if="
                salesOrder.client_validated_status ==
                CLIENT_VALIDATED_STATUS_DRAFT
              "
            >
              <button
                type="button"
                class="accept"
                :disabled="!checked || !isClientInfosValid"
                @click="acceptModal = true"
              >
                <img src="../../../../public/check.svg" alt="icon" />
                {{ $t("SALES_ORDERS.ACCEPT") }}
              </button>
              <button type="button" class="refuse" @click="refuseModal = true">
                <img src="../../../../public/times.svg" alt="icon" />
                {{ $t("SALES_ORDERS.REFUSE") }}
              </button>
            </div>
          </form>

          <div
            class="success-message"
            v-if="
              salesOrder.client_validated_status ==
              CLIENT_VALIDATED_STATUS_VALIDATED
            "
          >
            <p>
              {{ $t("SALES_ORDERS.SALES_ORDER_VALIDATED") }}
            </p>
          </div>
          <div
            class="error-message"
            v-if="
              salesOrder.client_validated_status ==
              CLIENT_VALIDATED_STATUS_CANCELED
            "
          >
            <p>
              {{ $t("SALES_ORDERS.SALES_ORDER_CANCELED") }}
            </p>
          </div>
          <button class="download" @click="downloadPdf">
            <img src="../../../../public/file-pdf-outline.svg" alt="icon" />
            <span>{{ $t("SALES_ORDERS.DOWNLOAD_PDF") }}</span>
          </button>
        </div>
      </div>
    </div>

    <modal :show.sync="acceptModal" modal-classes="modal-secondary accept">
      <div class="icon">
        <img src="../../../../public/check-outline.svg" alt="icon" />
      </div>
      <p v-html="$t('SALES_ORDERS.ALERT_VALIDATE')"></p>
      <template slot="footer">
        <Button class="no" @click="acceptModal = false">
          {{ $t("COMMON.CANCEL") }}
        </Button>
        <Button class="yes" @click="validate">
          {{ $t("SALES_ORDERS.ACCEPT_VALIDATE") }}
        </Button>
      </template>
    </modal>

    <modal :show.sync="refuseModal" modal-classes="modal-secondary refuse">
      <div class="icon">
        <img src="../../../../public/close.svg" alt="icon" />
      </div>
      <p v-html="$t('SALES_ORDERS.ALERT_CANCEL')"></p>
      <template slot="footer">
        <Button class="no" @click="refuseModal = false">
          {{ $t("COMMON.CANCEL") }}
        </Button>
        <Button class="yes" @click="cancel">
          {{ $t("SALES_ORDERS.ACCEPT_CANCEL") }}
        </Button>
      </template>
    </modal>
  </div>
</template>
<script>
import swal from "sweetalert2";
import {
  CLIENT_VALIDATED_STATUS_DRAFT,
  CLIENT_VALIDATED_STATUS_VALIDATED,
  CLIENT_VALIDATED_STATUS_CANCELED,
} from "@/constants/salesOrders";
import { Table, TableColumn, Button, Input } from "element-ui";
import { validate } from "vee-validate";

export default {
  layout: "DashboardLayout",

  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [Input.name]: Input,
    [TableColumn.name]: TableColumn,
  },

  methods: {},

  data() {
    return {
      validatorInfos: {
        lastName: "",
        firstName: "",
        email: "",
        comment: "",
        position: "",
      },
      checked: false,
      acceptModal: false,
      refuseModal: false,
      salesOrder: null,
      CLIENT_VALIDATED_STATUS_DRAFT,
      CLIENT_VALIDATED_STATUS_VALIDATED,
      CLIENT_VALIDATED_STATUS_CANCELED,
    };
  },

  created() {
    this.get();
  },

  computed: {
    isClientInfosValid() {
      return (
        !!this.validatorInfos.firstName &&
        !!this.validatorInfos.lastName &&
        !!this.validatorInfos.position &&
        !!this.validatorInfos.email
      );
    },
  },

  methods: {
    async get() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const id = this.$route.params.id;
        const token = this.$route.params.token;
        await this.$store.dispatch("salesOrders/publicGet", { id, token });
        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];
        if (!this.salesOrder) {
          this.$router.push("/not-found");
        }
        this.validatorInfos = {
          lastName: this.salesOrder.client_validated_by_lastname,
          firstName: this.salesOrder.client_validated_by_firstname,
          email: this.salesOrder.client_validated_by_email,
          comment: this.salesOrder.client_validated_by_comment,
          position: this.salesOrder.client_validated_by_position,
        };
        this.checked =
          this.salesOrder.client_validated_status ==
          CLIENT_VALIDATED_STATUS_VALIDATED;
      } catch (error) {
        console.log(error);
        this.$router.push("/not-found");
      }
      swal.close();
    },

    async downloadPdf() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const id = this.$route.params.id;
        const token = this.$route.params.token;
        const response = await this.$store.dispatch(
          "salesOrders/publicDownloadPdf",
          { id, token }
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");

        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async validate() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const id = this.$route.params.id;
        const token = this.$route.params.token;
        await this.$store.dispatch("salesOrders/publicValidate", {
          id,
          token,
          validatorInfos: this.validatorInfos,
        });
        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.acceptModal = false;
      } catch (error) {
        alert(error);
      }
      swal.close();
    },

    async cancel() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const id = this.$route.params.id;
        const token = this.$route.params.token;
        await this.$store.dispatch("salesOrders/publicCancel", {
          id,
          token,
          validatorInfos: this.validatorInfos,
        });
        this.salesOrder = this.$store.getters["salesOrders/salesOrder"];
        this.refuseModal = false;
      } catch (error) {
        alert(error);
      }
      swal.close();
    },
  },
};
</script>
